<!-- 面包屑组件 -->
<template>
  <div class="breadcrumb">
    <el-breadcrumb>
      <el-breadcrumb-item :to="item.id" v-for="(item, index) in $route.meta.breadcrumb" :key="item.id"
        :class="{ active: index === $route.meta.breadcrumb!.length - 1 }">
        {{ $t(item.text) }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script lang="ts" setup></script>

<script lang="ts">
export default {
  name: 'BaseBreadCrumb',
}
</script>

<style scoped lang="scss">
.breadcrumb {
  margin: 20px 0;

  :deep(.el-breadcrumb__inner.is-link) {
    font-weight: normal;
  }

  .active {
    :deep(.el-breadcrumb__inner) {
      color: #333;
      font-weight: bold;
    }
  }

  :deep(.el-breadcrumb) {
    font-size: 16px;
  }

}
</style>
